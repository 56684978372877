<template>
  <div class="w-full flex justify-center items-center relative max-w-screen-xl overflow-hidden">
    <ScrollReveal v-slot="{ isActive }" :offset="400" class="mx-auto w-full sm:h-[30vw] h-[30vh] flex justify-center items-center [--duration:500ms]">
        <div :class="[isActive?'':'opacity-10']" class="w-full flex justify-center items-center text-[7vw] text-center  font-brand relative overflow-hidden duration-500 ">
        <div class="z-10 text-white pr-20">[</div>
        <div :class="[isActive?'max-w-[100vw]':'max-w-0','duration-500 overflow-hidden flex justify-center items-center opacity-0']">
          <slot/>
        </div>

        <div :class="[isActive?'opacity-100 duration-500 delay-[0.2s]  scale-100':'opacity-0 scale-50 duration-300',' absolute ']">
          <slot/>
        </div>


        <div class="z-10 text-white pl-20">]</div>
      </div> 
    </ScrollReveal>
    <!-- <div class="h-[50vw] w-[50vw] absolute bg-turquoise-500 blur-[200px] rounded-full z-0 opacity-30"></div> -->
  </div>
</template>

<script setup>

  const { $anime } = useNuxtApp()


</script>

<style>

</style>